// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dN_F4";
export var careerWorking__btnWrapper = "dN_F3";
export var careerWorking__card = "dN_FT";
export var careerWorking__cardBgDark = "dN_FW";
export var careerWorking__cardBgLight = "dN_FV";
export var careerWorking__cardDescription = "dN_F1";
export var careerWorking__cardHorizontal = "dN_FX";
export var careerWorking__cardHorizontalBox = "dN_FY";
export var careerWorking__cardHorizontalImage = "dN_FZ";
export var careerWorking__cardImage = "dN_F2";
export var careerWorking__cardTitle = "dN_F0";